import React from 'react'
import { graphql } from 'gatsby'
import Wrapper from "../components/Wrapper";
import Show from "../components/Show";
import Video from "../components/Video";
import Releases from "../components/Releases";
import BIO from "../components/Bio";
import BANDPHOTOS from "../components/Bandphotos";
import LOGOS from "../components/Logos";
import CONTACT from "../components/Contact";
import PRESS_QUOTES from "../components/PressQuotes";
import CONCERT_REVIEWS from "../components/ConcertReviews";
import Img from "gatsby-image";

export const EPK_QUERY = graphql`
query EPK_QUERY ($yesterday: Date) {
  epkItem: allContentfulEpk(limit: 1) {
    edges {
      node {
        bioLongNl {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        bioMediumNl {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        bioShortNl {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        bioLongEn {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        bioMediumEn {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        bioShortEn {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        contactInfo {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        bandPhotos {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }            
          file {
            url
          }
        }
        bandPhotos {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }            
          file {
            url
          }
        }
        logos {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }              
          file {
            url
          }
        }
        coverImage {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }            
          file {
            url
          }
        }
      }
    }
  }
  latestRelease: allContentfulReleases(sort: {fields: releaseDate, order: DESC}, limit: 2) {
    edges {
      node {
        title
        showTitleOnHomepage
        streamingURLs
        releaseDate
        images {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        relatedMerch {
          url
          sizes
          price
          name
          description {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 512)
            }
          }
          images {
            fixed(width: 750) {
              src
            }
          }
        }
        tracks {
          tracks
        }
      }
    }
  }
  pressQuoteItems: allContentfulPressQuotes {
    edges {
      node {
        quote {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        quoteBy
        quoteUrl
        quoteLinkTitle
      }
    }
  }  
  #concertReviewItems: allContentfulConcertReviews {
  #  edges {
  #    node {
  #      review {
  #        childMarkdownRemark {
  #          html
  #          excerpt(pruneLength: 512)
  #        }
  #      }
  #      reviewBy
  #      reviewUrl
  #      reviewLinkTitle
  #    }
  #  }
  #}  
  showList: allContentfulShows(filter: {start: {gte: $yesterday}}, sort: {fields: start, order: ASC}) {
    edges {
      node {
        ticketPrice
        ticketUrl
        name
        locationUrl
        locationStreetAddress
        locationPostalCode
        locationName
        end
        start
        locationCity
        description {
          childMarkdownRemark {
            excerpt(pruneLength: 512)
          }
        }
      }
    }
  }
  releaseItems: allContentfulReleases(sort: {fields: releaseDate, order: DESC}) {
    edges {
      node {
        title
        showTitleOnHomepage
        streamingURLs
        releaseDate
        images {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        relatedMerch {
          url
          sizes
          price
          name
          description {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 512)
            }
          }
          images {
            fixed(width: 750) {
              src
            }
          }
        }
        tracks {
          tracks
        }
      }
    }
  }
  videoItems: allContentfulVideos(sort: {fields: sortingWeight, order: ASC}) {
    edges {
      node {
        title
        youtubeId
      }
    }
  }
}
`;

const EPKPage = ({data:{epkItem, pressQuoteItems, latestRelease, concertReviewItems, showList, videoItems, releaseItems, meta }}) => {
  const epk = epkItem.edges[0].node || false
  const shows = showList.edges.length && showList.edges || false
  const videos = videoItems.edges || false
  const releases = releaseItems.edges || false
  const pressQuotes = pressQuoteItems && pressQuoteItems.edges || false
  const concertReviews = concertReviewItems && concertReviewItems.edges || false
  const release = latestRelease.edges[1] || false
  const breakTheFrameRelease = latestRelease.edges[0] || false

  return (<Wrapper>
    {/* Break The Frame release */}
    { breakTheFrameRelease && <Releases releases={[breakTheFrameRelease]} showTitle={false} /> }
    <section>
      <h2>Break The Frame / Dam That River</h2>
      <p>
        We're back with our new single "Break The Frame"!
        The single includes one original track, "Break the Frame," as well as a cover of "Dam That River" by Alice In Chains.
      </p>
      <h3>Break The Frame</h3>
      <p>
        Our new one. Recorded in the legendary Studio 195 on the Dutch/Belgian border by Patrick Delabie. How many studios are still around where a computer screen is nowhere to be found in the entire building? Well at least one. Back to our roots. Old school and unpolished. Musically it continues where we left it off on our debut album. A mix of rock and metal with a slight progressive twist.
        Lyrically. It's open for your own interpretation. If you listen closely to Pim, the one that sounds like a hurricane flattening a skyscraper, it seems that he's apparently picked up an interest in the nautical world.
      </p>
      <h3>Dam That River (Alice in Chains)</h3>
      <p>
        It won't be a huge surprise when listening to the average TKH song, if I tell you we grew up in the 90's. Some of our musical eyes were opened by Nirvana and the likes, while the others where fully emerged in the metal and indie scene of those days. But there was one band that was on all of our minds and walls in those days and that band is called Alice In Chains. We chose the second song on their second album DIRT to pay our dues and homage to this giant source of inspiration.
        Without this song blasting on repeat in my teenage room, I truly doubt that I would've been typing these words today.
      </p>
    </section>

    {/* Videos */}
    { videos && <section className="c-videos">
      <h2>Videos</h2>
      <ol className="c-videos__list">{
        videos.map(({ node }) => {
          return(<li className="c-videos__item" key={node.title}>
            <Video {... node} />
          </li>)
        })
      }</ol>
    </section> }

    {/* Album release */}
    { release && <Releases releases={[release]} showTitle={false} /> }
    <section>
      <h2>OUT NOW + IN STORES</h2>
      <p>
        We are proud to announce that our first album is released via WormHoleDeath Records. In WHD we found a partner in which we recognize the unconditional love for music and we are excited to join them in their mission to bring fine music around the globe.
        This album is very dear to us; it is the result of our friendship, of our addiction to heavy and melodic music, and of countless hours in our rehearsal room. Now, the time has come to release it to the masses. This record is our first statement and we are ready, fired up and determined to back it up on stage, as soon as humanly possible. Anytime, anywhere. This is just the beginning!
      </p>
    </section>
    <article>
      <h2>Track list</h2>
      <ol className="track-list-column">
        <li>Rise</li>
        <li>The Far Beyond</li>
        <li>The Fall</li>
        <li>Anymore</li>
        <li>Older</li>
        <li>Miss Comprehension</li>
        <li>Borderline</li>
        <li>Should I Be Home</li>
        <li>Mountainess</li>
        <li>Waiting</li>
        <li>The White Horse</li>
      </ol>
    </article>

    {/* Press quotes */}
    { pressQuotes && <PRESS_QUOTES pressQuotes={pressQuotes} /> }

    {/* Contact info */}
    { epk && <CONTACT {... epk} /> }

    {/* BIO */}
    { epk && <BIO {... epk} /> }

    {/* Band photos */}
    { epk && <BANDPHOTOS {... epk} /> }

    {/* Releases */}
    { releases && <Releases releases={releases} showTitle={true} /> }

    {/* Logos */}
    { epk && <LOGOS {... epk} /> }

    {/* Concert reviews */}
    { concertReviews && <CONCERT_REVIEWS concertReviews={concertReviews} /> }

    {/* Shows */}
    { shows && <section className="c-shows">
      <h2>Shows</h2>
      <ol className="c-shows__list">{
        shows.map(({ node }) => {
          return(<li className="c-shows__item" key={node.name}>
            <Show {... node} />
          </li>)
        })
      }</ol>
    </section> }

  </Wrapper>)
}

export default EPKPage;
